import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { sentenceCase } from 'change-case';
// @mui
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  IconButton,
  TableContainer,
} from '@mui/material';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Label from '../../../../components/label';
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import MenuPopover from '../../../../components/menu-popover';
import { TableHeadCustom } from '../../../../components/table';
import { approvePointsEarnContract } from 'src/utils/chart_calculation_ver1';
import { useSnackbar } from '../../../../components/snackbar';
import { fDate, fDateTime } from 'src/utils/formatTime';

// ----------------------------------------------------------------------

AppAgreements.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.array,
  subheader: PropTypes.string,
  tableLabels: PropTypes.array,
};

export default function AppAgreements({ title, subheader, tableData, tableLabels, ...other }) {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.map((row) => (
                <AppAgreementsRow key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        >
          View All
        </Button>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

AppAgreementsRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    partnerName: PropTypes.string,
    partnerEmail: PropTypes.string,
    term: PropTypes.string,
    status: PropTypes.string,
    createdOn: PropTypes.date,
    category: PropTypes.string,
  }),
};

function AppAgreementsRow({ row }) {
  const [openPopover, setOpenPopover] = useState(null);
  const navigate = useNavigate();

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleDownload = () => {
    handleClosePopover();
    console.log('DOWNLOAD', row.id);
  };

  const handlePrint = () => {
    handleClosePopover();
    console.log('PRINT', row.id);
  };

  const handleShare = () => {
    handleClosePopover();
    console.log('SHARE', row.id);
  };

  const handleReject = () => {
    handleClosePopover();
    console.log('DELETE', row.id);
    let contract = JSON.parse(localStorage.getItem("contract"));
    contract.status = "rejected";
    localStorage.setItem("contract", JSON.stringify(contract));
  };

  const handleApprove = async () => {
    handleClosePopover();
    // console.log('DELETE', row.id);
    // let contract = JSON.parse(localStorage.getItem("contract"));
    // contract.status = "approved";
    // localStorage.setItem("contract", JSON.stringify(contract));

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_PARTNERHUB_OPERATOR_URL}/v1/operator/approve-points-earn-contract`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: { identifier: row.identifier }
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        enqueueSnackbar(response.data, {
          variant: 'success',
        })
      })
      .catch(function (error) {
        console.log();
        enqueueSnackbar(error.response.data.message, {
          variant: 'error',
        })
      });


  };

  const handleView = () => {
    handleClosePopover();
    console.log('VIEW', row.id);
    navigate("/dashboard/smartcontract/view/" + row.identifier);
  };

  // { id: "EK_CA_23523", type: "Points Earn Contract", partnerName: "Careem Inc.", proposalDate: "21 March 2023", term: "1 Year", status: "Pending" }

  return (
    <>
      <TableRow>
        <TableCell>{`ESC_${JSON.stringify(row.identifier).slice(1, 7)}`}</TableCell>

        <TableCell>Points Earn Contract</TableCell>
        <TableCell>{row.companyDetails.companyName}</TableCell>
        <TableCell>{row.companyDetails.contactEmail}</TableCell>
        <TableCell>{fDateTime(row.createdOn)}</TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              (row.status === 'pending' && 'warning') ||
              (row.status === 'rejected' && 'error') ||
              'success'
            }
          >
            {sentenceCase(row.status)}
          </Label>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={handleView}>
          <Iconify icon="eva:eye-fill" />
          View
        </MenuItem>
        <MenuItem onClick={handleDownload}>
          <Iconify icon="eva:download-fill" />
          Download
        </MenuItem>

        <MenuItem onClick={handlePrint}>
          <Iconify icon="eva:printer-fill" />
          Print
        </MenuItem>

        <MenuItem onClick={handleShare}>
          <Iconify icon="eva:share-fill" />
          Share
        </MenuItem>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <MenuItem onClick={handleApprove} sx={{ color: 'success.main' }}>
          <Iconify icon="eva:trash-2-outline" />
          Approve
        </MenuItem>
        <MenuItem onClick={handleReject} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" />
          Reject
        </MenuItem> */}
      </MenuPopover>
    </>
  );
}
