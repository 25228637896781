
import { m } from 'framer-motion';
import {
    Button, Typography, TextField, Stack, Container, Grid,
    Stepper,
    StepLabel,
    Box,
    Step,
    Paper,
    StepContent,
    MenuItem,
    CardHeader,
    CardContent,
    Tooltip,
    Card,
    Checkbox,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Chip,
} from '@mui/material';
import { useEffect,useState } from 'react';
import { varFade, MotionViewport } from '../../components/animate';
import { ChartColumnSingle1 } from '../_examples/extra/chart';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { TableHeadCustom } from 'src/components/table';
import wip_img from '../../assets/logo/WIP.png';

export function PartnershipDetails({ companyDetails, handleChange }) {

    const [formattedDate, setFormattedDate] = useState('');
    const [campaign,setCampaign] = useState([{id:"campaign1",name:"campaign 1"}])

    useEffect(() => {
        handleTimestampChange()
    },[])

    const handleTimestampChange = (event) => {
       
    
        // Convert timestamp to Date
        const date = new Date(parseInt(companyDetails.proposedDate));
        
        // Format the date as desired ("yyyy-mm-dd")
        const formatted = date.toISOString().slice(0,10); // This gives the date in the format "yyyy-mm-dd"
        
        // Set the formatted date in the state
        setFormattedDate(formatted);
      };



      const campaignDropdown = [
        {title:"None"},
        {title:"Social media"},
        {title:"At store info"},
        {title:"At store banner"},
        {title:"Push notifications"},
      ]

    // const date = new Date(parseInt(companyDetails.proposedDate));

    // console.log(companyDetails.proposedDate,"companyDetails.proposedDate")
    // console.log(date,"date-----")

    // let date1 = "2024-04-22"

    const handleAddCampaign = () => {
        console.log("new campaign added")
        // console.log(campaign,"campaign----")
        const newObj = {id:`campaign${campaign.length+1}`,name:`campaign ${campaign.length+1}`,}
        setCampaign([...campaign,newObj])
    }

    console.log(campaign,"campaign+++++++00000000")
    return (
        <>
            <Stack style={{ padding: "40px" }} component={MotionViewport} spacing={3}>
                {/* <div style={{ float: 'right' }}>
                    <img width='60' src={wip_img} />
                    <Typography variant='body2' sx={{ fontWeight: '600', color: '#f44747' }}>Details required for this page will be tailored to your needs.</Typography>
                </div> */}
                <Typography variant='h4' style={{ textAlign: 'left' }} >Partnership Details</Typography>
                <Typography variant='h6' style={{ textAlign: 'left' }} >Tell us more about your plan for the partnership</Typography>
                <TextField size='small' fullWidth label="Proposed Date" type="date" 
                value={companyDetails.proposedDate}
                // value={formattedDate}
                 id="proposedDate" onChange={(e) => handleChange(e, "companyDetails", "proposedDate")} />

                <Typography variant='h6' style={{ textAlign: 'left' }}>Could you tell us briefly about why you would like to enter this partnership?</Typography>
                <m.div>
                    <TextField size='small' fullWidth label="Enter details here." multiline rows={4} value={companyDetails.additionalDetails} id="additionalDetails" onChange={(e) => handleChange(e, "companyDetails", "additionalDetails")} />
                </m.div>

                <Typography variant='h6' style={{ textAlign: 'left' }} >How will the partnership be promoted?</Typography>
                <p>for example social media, in store, etc.</p>
                {/* -------------------------------- */}
                   
                {/* <TextField size='small' variant="outlined" select fullWidth label="Sales ($)" value={volume.sales} id="volume.sales" onChange={(e) => handleChange(e, "volume", "sales")} >
                            {sales.map((item) => (
                                <MenuItem key={item.key} value={item.key}>{item.name}</MenuItem>
                            ))}
                        </TextField> */}


                <Grid container spacing={3}>
                    {/* <Grid item xs={12} md={4}>
                        <m.div>
                            <TextField size='small' variant="outlined" select fullWidth label="Campaign 1" 
                             value={companyDetails.campaign1} id="campaign1" onChange={(e) => handleChange(e, "companyDetails", "campaign1")} 
                            >
                            {campaignDropdown.map((item) => (
                                <MenuItem 
                                // key={item.key} 
                                value={item.title}
                                >{item.title}</MenuItem>
                            ))}
                        </TextField>
                        </m.div>
                    </Grid> */}
                    {campaign.map((campItem,campIndex) => {
                        return (
                            <Grid item xs={12} md={4}>
                        <m.div>
                            <TextField size='small' variant="outlined" select fullWidth label={campItem.name} 
                             value={companyDetails[`campaign${campIndex + 1}`]} id={campItem.id} onChange={(e) => handleChange(e, "companyDetails", `${campItem.id}`)} 
                            >
                            {campaignDropdown.map((item) => (
                                <MenuItem 
                                // key={item.key} 
                                value={item.title}
                                >{item.title}</MenuItem>
                            ))}
                        </TextField>
                        </m.div>
                    </Grid>
                        )
                    })}
                    {/* -----need to change the style---- */}
                    {campaign.length <=2 && <Button onClick={()=>handleAddCampaign()}>+</Button>}
                    
                    {/* <Grid item xs={12} md={4}>
                        <m.div>
                            <TextField size='small' fullWidth label="Campaign 2" value={companyDetails.campaign2} id="campaign2" onChange={(e) => handleChange(e, "companyDetails", "campaign2")} />
                        </m.div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <m.div>
                            <TextField size='small' fullWidth label="Campaign 3" value={companyDetails.campaign3} id="campaign3" onChange={(e) => handleChange(e, "companyDetails", "campaign3")} />
                        </m.div>
                    </Grid> */}
                </Grid>
                 {/* ----------------------------------- */}
                {/* <Typography variant='h6' style={{ textAlign: 'left' }} >How are you planning to issue the points</Typography> */}
                {/* <Grid container spacing={3}>
                    <Grid xs={12} md={12}>
                        <Checkbox defaultChecked /> Earn API 
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Checkbox /> Fixed QR Code
                    </Grid>
                    --------------------------------
                    <Grid xs={12} md={12}>
                        <Checkbox disabled /> Points Exchange <Chip label="Coming Soon" variant="contained" color="primary" size="small" />
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Checkbox disabled /> Card Linked Offers <Chip label="Coming Soon" variant="contained" color="primary" size="small" />
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Checkbox disabled /> Affiliate Link <Chip label="Coming Soon" variant="contained" color="primary" size="small" />
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Checkbox disabled /> Gift Card <Chip label="Coming Soon" variant="contained" color="primary" size="small" />
                    </Grid>
                    ----------------------------------
                </Grid> */}
            </Stack>
        </>
    )
}