import {
  Button,
  Typography,
  TextField,
  Stack,
  Container,
  Grid,
  Stepper,
  StepLabel,
  Box,
  Step,
  Paper,
  StepContent,
  MenuItem,
  CardHeader,
  CardContent,
  Tooltip,
  Card,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  getBonusIssuedPerYear,
  getExclusivityList,
  getNoticePeriodList,
  getPaymentList,
  getProgramContacts,
} from 'src/utils/chart_calculation_ver1';
import { useLocation } from 'react-router-dom';

export default function ContractSummary({
  companyDetails,
  volume,
  price,
  isChecked,
  toogleCheck,
  programUsingDomain,
}) {
  const [programContact, setProgramContacts] = useState([]);
  const [contractManager, setContractManager] = useState([]);
  // console.log(programUsingDomain,"programUsingDomain")

  // const contractManager = programContact.filter((item) => {
  //     return item.contactRole === "Contract Manager"
  // })

  // console.log(contractManager,"manager")

  useEffect(() => {
    let filteredContractManager = {};
    if (programContact.length > 0) {
      //   console.log(programContact, 'programContact----');
      filteredContractManager = programContact?.filter((item) => {
        return item.contactRole === 'Contract Manager';
      });
    }
    console.log(filteredContractManager, 'manager');
    setContractManager(filteredContractManager);
  }, [programContact]);

  useEffect(() => {
    getProgramContact();
  }, []);

  const getProgramContact = async () => {
    console.log(programUsingDomain, '++++++++++++++++');

    const response = await getProgramContacts(programUsingDomain?.id);
    const data = response;
    setProgramContacts(data);
  };

  console.log(companyDetails, 'companyDetails');

  const location = useLocation();
  const currentUrl = location.pathname;
  console.log(currentUrl);
  return (
    <>
      {/* <Typography variant='h4' sx={{ textAlign: 'center' }}>Partner Hub Partnership Agreement​</Typography> */}
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        Program Name partnership commercials​
      </Typography>
      <hr />
      <br />
      {/* potential start date */}
      {/* <Typography>This agreement is dated  on <b>{new Date().toLocaleString()} GST</b> and is between <br /> */}
      <Typography>
        This agreement is dated on <b>{companyDetails.proposedDate} GST</b> and is between <br />
        <b>
          {companyDetails.companyName}, {companyDetails.contactPhoneNo}
        </b>
        , registered at {companyDetails.companyAddress} <br />
        and
        <br />
        {/* <b> Reward Miles </b>+971-55545322, Registered at Clock Tower, Al Maktoum Rd - Dubai.</Typography><br /> */}
        <b> Reward Miles </b>
        {programUsingDomain.phone}, Registered at {programUsingDomain.address}
      </Typography>{' '}
      <br />
      {/* This agreement sets out what has been agreed between Partner and Operator.<br /> <br /> */}
      This commercial construct sets out what has been agreed between Partner and Operator.
      <br /> <br />
      <Typography variant="h6">SMART CONTRACT COVER SHEET:</Typography>
      <br />
      <Grid container spacing={3}>
        <Grid item md={6}>
          <b>OPERATOR REPRESENTATIVE</b> <br />
          {/* "Sponsor" - Name, Title, Mobile, Email <br /> */}
          {/* "Contract Manager" - John Doe, Sr. Program Manager, +971-55545322, john.doe@abcairlines.com <br /> */}
          "Contract Manager" - {contractManager[0]?.contactName}, {contractManager[0]?.contactRole},{' '}
          {contractManager[0]?.programContactPhoneVOList[0]?.phoneCountryCode}{' '}
          {contractManager[0]?.programContactPhoneVOList.length > 0 &&
            contractManager[0]?.programContactPhoneVOList[0]?.phoneNumber}
          , {contractManager[0]?.contactEmail} <br />
        </Grid>
        <Grid item md={6}>
          <b>PARTNER REPRESENTATIVE</b> <br />
          {/* "Sponsor" - Name, Title, Mobile, Email <br /> */}
          "Contract Manager" - {companyDetails.contactName}, Brand Manager,{' '}
          {companyDetails.contactPhoneNo}, {companyDetails.contactEmail} <br />
        </Grid>
        <Grid item md={6}>
          {/* <b>COMMENCEMENT DATE</b> <br /> */}
          <b>INTENDED COMMENCEMENT</b> <br />
          Means the date of which the service is first available to Members, which will be no later
          than <b>{new Date().toLocaleString()} GST</b>.​
          <br />
        </Grid>
        <Grid item md={6}>
          <b>INITIAL TERM</b> <br />
          <b>3 YEARS</b> with the right to review after 12 months in the event that total payments
          to OPERATOR do not exceed 10% of target volume in any 12 month period. <br />
        </Grid>
        <Grid item md={6}>
          <b>PRICE ASSUMPTION</b> <br />
          The points costs opposite assume the following:
          <ul style={{ paddingLeft: '20px' }}>
            <li>
              Projected Sales Volume of {volume.annualPointsPotential.toLocaleString('en-us')} miles
              per quarter
            </li>
            <li>
              Bonus Issued Per Year - {getBonusIssuedPerYear()[price.bonusIssuedPerYear].name}​
            </li>
            <li>Notice - {getNoticePeriodList()[price.notice].name} notice</li>
            <li>Exclusivity - {getExclusivityList()[price.exclusivity].name}</li>
            <li>Payment Terms - {getPaymentList()[price.payment].name}</li>
          </ul>
        </Grid>
        <Grid item md={6}>
          <b>POINT COSTS</b> <br />
          Cost per point per quarter based on price assumptions:
          <ul style={{ paddingLeft: '20px' }}>
            <li>
              Base Price - ${Number(price.tierPricing[4]).toFixed(4)} ({price.tierVolume[5]} -{' '}
              {price.tierVolume[4]} points)
            </li>
            <li>
              Tier 1 Price - ${Number(price.tierPricing[3]).toFixed(4)} ({price.tierVolume[4]} -{' '}
              {price.tierVolume[3]} points)
            </li>
            <li>
              Tier 2 Price - ${Number(price.tierPricing[2]).toFixed(4)} ({price.tierVolume[3]} -{' '}
              {price.tierVolume[2]} points)
            </li>
            <li>
              Target Price - ${Number(price.tierPricing[1]).toFixed(4)} ({price.tierVolume[2]} -{' '}
              {price.tierVolume[1]} points)
            </li>
            <li>
              Bonus Price - ${Number(price.tierPricing[0]).toFixed(4)} (Above {price.tierVolume[1]}{' '}
              points)
            </li>
          </ul>
        </Grid>
        <Grid item md={6}>
          <b>PARTNER PROMOTION</b> <br />
          During the Term PARTNER will promote the partnership via:
          <ul style={{ paddingLeft: '20px' }}>
            <li>{companyDetails.campaign1 || 'Campaign 1'}</li>
            <li>{companyDetails.campaign2 || 'Campaign 2'}</li>
            <li>{companyDetails.campaign3 || 'Campaign 3'}</li>
          </ul>
        </Grid>
        <Grid item md={6}>
          <b>OPERATOR PROMOTION</b> <br />
          During the Term OPERATOR will promote the partnership via:
          <ul style={{ paddingLeft: '20px' }}>
            <li>Partner page on website</li>
            <li>Offers page on website</li>
            <li>Member newsletters</li>
          </ul>
        </Grid>
        {/* <Grid item md={6}>
                    <b>PARTNER Authorised Signatory</b> <br />
                    Name: <br />
                    <br />
                    <br />
                    Signature:<br />
                    Date:
                </Grid>
                <Grid item md={6}>
                    <b>OPERATOR Authorised Signatory</b> <br />
                    Name: <br />
                    <br />
                    <br />
                    Signature:<br />
                    Date:
                </Grid> */}
      </Grid>
      <Box sx={{ marginTop: '15px' }}>
        {/* <Typography  variant='caption'>
                <Checkbox value={isChecked} onChange={() => { toogleCheck() }} /> I agree to the Points Partnership <a href="/assets/docs/parternship-contract-terms-and-conditions.pdf" target={'_blank'}>Terms and Conditions</a> </Typography> */}
        <Typography variant="caption">
          <Checkbox
            value={isChecked}
            onChange={() => {
              toogleCheck();
            }}
          />{' '}
          I agree to the Points Partnership{' '}
          <a
            href="https://loyyal-assets.s3.me-south-1.amazonaws.com/contract-management/contract_managem_ethiopianairlines_partnership_agreement.pdf"
            target={'_blank'}
          >
            Terms and Conditions
          </a>{' '}
        </Typography>
      </Box>
    </>
  );
}
