import axios from 'axios';

export function calculateVolumeChart() {}

export function calculatePriceChart() {}

// getDefaultCompanyConfiguration

// REACT_APP_CORE_ENGINE_URL

export async function getProgramUsingDomain() {
  var config = {
    method: 'get',
    // url :`${process.env.REACT_APP_CORE_ENGINE_URL}/getProgramUsingDomain?domain=ethiopianairlines.accesspoint.dev.loyyal.io`,
    url: `${process.env.REACT_APP_CORE_ENGINE_URL}/program/getProgramUsingDomain`,
    headers: {
      Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTM5NTI0MDEsImlzcyI6ImNvcmUtZW5naW5lLXNlcnZpY2UiLCJzdWIiOiJhYW1pci5tb2hkQGxveXlhbC5jb20iLCJhdWQiOlsiY29yZS1lbmdpbmUtc2VydmljZSIsImNvbnRyYWN0LW1hbmFnZW1lbnQtc2VydmljZSIsImFsbC1sb3l5YWwtZnJvbnRlbmRzIl0sImF1dGhvcml6ZWQiOnRydWUsIm5hbWUiOiJBYW1pciIsInVzZXJuYW1lIjoiYWFtaXIubW9oZEBsb3l5YWwuY29tIiwicm9sZUNvZGUiOiJMT1lZQUxfQURNSU4iLCJleHAiOjE3NDU0ODg0MDF9.BQ35nGRf2xt6YUokI6DUoZ0RmnyJPZLb4Wn2CGCu1PfQx9tFN0OtHM4cW4IQxlEfzzGE9bl3V8rNImpqVJS9uhEDF5Jey2NvJHUFUoW03rsOEHxKSyu9whMQ9ez92isqbb-T5x_ovKFjSINiETm5Kalr_u8QDaC_904enxWrIRrDECYtuBIZvN67DxoaryhEDjpaLQ_tQ1GD1P6IuFZtkaPzv1skxbv72M1r1C8_b870qwrtI2iJXIai8RvtNvyuEiU-yGMyjMDsg7lKTzl5IKM72WiVT4h5qvy3_kjnIt4133Rr-i0PcZHwpZ4LPsIWvV3gz5qTwpaO17YCKzZVIg`,
    },
    params: {
      domain: 'ethiopianairlines.accesspoint.dev.loyyal.io',
    },
  };
  const response = await axios(config);
  console.log(response, 'response0000');
  return response.data;

  // console.log(process.env.REACT_APP_CORE_ENGINE_URL,"env")
}

// export async function getProgramContacts (programId) {
//     var config = {
//         method :"get",
//         url :`${process.env.REACT_APP_CORE_ENGINE_URL}/getProgramContacts`,
//         params: {
//             programId : programId
//         },
//       }
//       const response = await axios(config);
//           console.log(response,"response0000")
//           return response.data
// }
export async function getProgramContacts(programId) {
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_CORE_ENGINE_URL}/program/getProgramContacts`,
    params: {
      programId: programId,
    },
    headers: {
      Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTM5NTI0MDEsImlzcyI6ImNvcmUtZW5naW5lLXNlcnZpY2UiLCJzdWIiOiJhYW1pci5tb2hkQGxveXlhbC5jb20iLCJhdWQiOlsiY29yZS1lbmdpbmUtc2VydmljZSIsImNvbnRyYWN0LW1hbmFnZW1lbnQtc2VydmljZSIsImFsbC1sb3l5YWwtZnJvbnRlbmRzIl0sImF1dGhvcml6ZWQiOnRydWUsIm5hbWUiOiJBYW1pciIsInVzZXJuYW1lIjoiYWFtaXIubW9oZEBsb3l5YWwuY29tIiwicm9sZUNvZGUiOiJMT1lZQUxfQURNSU4iLCJleHAiOjE3NDU0ODg0MDF9.BQ35nGRf2xt6YUokI6DUoZ0RmnyJPZLb4Wn2CGCu1PfQx9tFN0OtHM4cW4IQxlEfzzGE9bl3V8rNImpqVJS9uhEDF5Jey2NvJHUFUoW03rsOEHxKSyu9whMQ9ez92isqbb-T5x_ovKFjSINiETm5Kalr_u8QDaC_904enxWrIRrDECYtuBIZvN67DxoaryhEDjpaLQ_tQ1GD1P6IuFZtkaPzv1skxbv72M1r1C8_b870qwrtI2iJXIai8RvtNvyuEiU-yGMyjMDsg7lKTzl5IKM72WiVT4h5qvy3_kjnIt4133Rr-i0PcZHwpZ4LPsIWvV3gz5qTwpaO17YCKzZVIg`,
    },
  };
  const response = await axios(config);
  console.log(response, 'response0000');
  return response.data;
}

export async function listPointsEarnContract(payload) {
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_PARTNERHUB_OPERATOR_URL}/v1/operator/list-points-earn-contract-requests`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  };
  const response = await axios(config);

  return response.data;
}

export async function listPointsBurnContract(payload) {
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_PARTNERHUB_OPERATOR_URL}/v1/operator/list-points-burn-contract-requests`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  };
  const response = await axios(config);

  return response.data;
}

// -------------------------
export async function validateEmailContractRequest(payload) {
  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_PARTNERHUB_SELF_SERVICE_URL}/v1/self-service/validate-email-contract-request`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  };
  const response = await axios(config);
  console.log(response);
  return response.data;
}
// -------------------------

export async function submitPointsEarnContract(payload) {
  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_PARTNERHUB_SELF_SERVICE_URL}/v1/self-service/save-points-earn-contract-request`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  };
  const response = await axios(config);
  console.log(response);
  return response.data;
}

export async function submitPointsBurnContract(payload) {
  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_PARTNERHUB_SELF_SERVICE_URL}/v1/self-service/save-points-burn-contract-request`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  };
  const response = await axios(config);
  console.log(response);
  return response.data;
}

export async function getDefaultContractDiscount() {
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_PARTNERHUB_SELF_SERVICE_URL}/v1/self-service/points-earn-contract/fetch-pricing-discount-configuration`,
    headers: {},
  };
  const response = await axios(config);
  console.log(response);
  console.log('response');
}

export async function getDefaultContractThreshold() {
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_PARTNERHUB_SELF_SERVICE_URL}/v1/self-service/points-earn-contract/fetch-pricing-threshold-configuration`,
    headers: {},
  };
  const response = await axios(config);
  console.log(response);
  return response.data;
}

export async function getDefaultContractStrategy() {
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_PARTNERHUB_SELF_SERVICE_URL}/v1/self-service/points-earn-contract/fetch-pricing-strategy-configuration`,
    headers: {},
  };
  const response = await axios(config);
  console.log(response);
  return response.data;
}

export async function getDefaultTargetPricingConfiguration() {
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_PARTNERHUB_SELF_SERVICE_URL}/v1/self-service/points-earn-contract/fetch-target-pricing-configuration`,
    headers: {},
  };
  const response = await axios(config);
  console.log(response);
  return response.data;
}

export async function getDefaultTargetVolumeConfiguration() {
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_PARTNERHUB_SELF_SERVICE_URL}/v1/self-service/points-earn-contract/fetch-target-volume-configuration`,
    headers: {},
  };
  const response = await axios(config);
  console.log(response);
  return response.data;
}

export async function getDefaultCompanyConfiguration() {
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_PARTNERHUB_SELF_SERVICE_URL}/v1/self-service/points-earn-contract/fetch-company-details-configuration`,
    headers: {},
  };
  const response = await axios(config);
  console.log(response);
  console.log('response');
  return response.data;
}

export function getDefaultPriceDiscount() {
  return {
    creditPerNewMember: '2',
    bonusPerPartnerActive: '1',
    discountPerBonus: '1',
    month1_notice: '20',
    month3_notice: '15',
    year1_notice: '10',
    year3_notice: '0',
    year5_notice: '-20',
    none_exclusivity: '0',
    ustoyou_exclusivity: '30',
    youtous_exclusivity: '-20',
    mutual_exclusivity: '-10',
    upfront_payment: '-20',
    days30_payment: '0',
    days60_payment: '10',
    days90_payment: '20',
  };
}

export function getDefaultPriceStrategy() {
  return {
    veryLargeSpend: 30000000,
    veryLargeVolume: 40000000,
    veryLargePrice: 0.75,
    largeSpend: 8000000,
    largeVolume: 10000000,
    largePrice: 0.8,
    mediumSpend: 150000,
    mediumVolume: 176000,
    mediumPrice: 0.85,
    smallSpend: 300000,
    smallVolume: 71000,
    smallPrice: 4.2,
    verySmallSpend: 500000,
    verySmallVolume: 333000,
    verySmallPrice: 1.5,
  };
}

export function savePriceStrategyAndDiscount(priceStrategy, contractDiscount) {
  localStorage.setItem('priceStrategy', JSON.stringify(priceStrategy));
  localStorage.setItem('contractDiscount', JSON.stringify(contractDiscount));
}

export function getContract() {
  localStorage.getItem('contract');
}

/**
 * @deprecated
 */
export function getMarketList() {
  return [
    { key: 0, name: 'Australia', value: 0 },
    { key: 1, name: 'Canada', value: 1 },
    { key: 2, name: 'China', value: 2 },
    { key: 3, name: 'Egypt', value: 3 },
    { key: 4, name: 'Indonesia', value: 4 },
    { key: 5, name: 'Japan', value: 5 },
    { key: 6, name: 'Kenya', value: 6 },
    { key: 7, name: 'Malaysia', value: 7 },
    { key: 8, name: 'New Zealand', value: 8 },
    { key: 9, name: 'Philippines', value: 9 },
    { key: 10, name: 'Saudi Arabia', value: 10 },
    { key: 12, name: 'Singapore', value: 12 },
    { key: 13, name: 'South Africa', value: 13 },
    { key: 14, name: 'South Korea', value: 14 },
    { key: 15, name: 'Thailand', value: 15 },
    { key: 16, name: 'United Arab Emirates', value: 16 },
    { key: 17, name: 'United Kingdom', value: 17 },
    { key: 18, name: 'United States', value: 18 },
    { key: 19, name: 'Vietnam', value: 19 },
  ];
}
/**
 * @deprecated
 */
export function getCategoryList() {
  return [
    { key: 0, name: 'Travel', value: 0 },
    { key: 1, name: 'Dining', value: 1 },
    { key: 2, name: 'Shopping', value: 2 },
    { key: 3, name: 'Entertainment', value: 3 },
    { key: 4, name: 'Energy', value: 4 },
    { key: 5, name: 'Banking', value: 5 },
    { key: 6, name: 'Insurance', value: 6 },
    { key: 7, name: 'Subscription', value: 7 },
    { key: 8, name: 'Others', value: 8 },
  ];
}
/**
 * @deprecated
 */
export function getSubCategoryList() {
  return [
    { key: 0, name: 'Flights', value: 0 },
    { key: 1, name: 'Hotels', value: 1 },
    { key: 2, name: 'Car Hire', value: 2 },
    { key: 3, name: 'Taxis', value: 3 },
    { key: 4, name: 'Cruises', value: 4 },
    { key: 5, name: 'Travel Booking', value: 5 },
    { key: 6, name: 'Trains', value: 6 },
    { key: 7, name: 'Buses', value: 7 },
    { key: 8, name: 'Parking', value: 8 },
  ];
}

export function getSalesList() {
  return [
    { key: 0, name: '1 Million Dollars', value: 1000000 },
    { key: 1, name: '2 Million Dollars', value: 2000000 },
    { key: 2, name: '5 Million Dollars', value: 5000000 },
    { key: 3, name: '10 Million Dollars', value: 10000000 },
    { key: 4, name: '20 Million Dollars', value: 20000000 },
  ];
}

export function getVolumePenetrationList() {
  return [
    { key: 0, name: '5%', value: 0.05 },
    { key: 1, name: '10%', value: 0.1 },
    { key: 2, name: '20%', value: 0.2 },
    { key: 3, name: '50%', value: 0.5 },
    { key: 4, name: '80%', value: 0.8 },
    { key: 5, name: '100%', value: 1 },
  ];
}

export function getVolumePropositionList() {
  return [
    { key: 0, name: '1 point per $', value: 1 },
    { key: 1, name: '2 point per $', value: 2 },
    { key: 2, name: '5 point per $', value: 5 },
    { key: 3, name: '10 point per $', value: 10 },
    { key: 4, name: '20 point per $', value: 20 },
  ];
}

export function getGrowthList() {
  return [
    { key: 0, name: '100%', value: 1 },
    { key: 1, name: '90%', value: 0.9 },
    { key: 2, name: '80%', value: 0.8 },
    { key: 3, name: '50%', value: 0.5 },
    { key: 4, name: '25%', value: 0.25 },
  ];
}

export function getCustomerPerYear() {
  return [
    { key: 0, name: '100 Thousands', value: 100000 },
    { key: 1, name: '200 Thousands', value: 200000 },
    { key: 2, name: '500 Thousands', value: 500000 },
    { key: 3, name: '1 Millions', value: 1000000 },
    { key: 4, name: '2 Millions', value: 2000000 },
    { key: 5, name: '5 Millions', value: 5000000 },
  ];
}

export function getNewToTheProgramList() {
  return [
    { key: 0, name: '5 %', value: 0.05 },
    { key: 1, name: '10 %', value: 0.1 },
    { key: 2, name: '20 %', value: 0.2 },
    { key: 3, name: '30 %', value: 0.3 },
    { key: 4, name: '50 %', value: 0.5 },
    { key: 5, name: '60 %', value: 0.6 },
    { key: 6, name: '80 %', value: 0.8 },
  ];
}

export function getCollectAtOtherPartnerList() {
  return [
    { key: 0, name: '5 %', value: 0.05 },
    { key: 1, name: '10 %', value: 0.1 },
    { key: 2, name: '20 %', value: 0.2 },
    { key: 3, name: '30 %', value: 0.3 },
    { key: 4, name: '50 %', value: 0.5 },
    { key: 5, name: '80 %', value: 0.8 },
  ];
}

export function getBonusIssuedPerYear() {
  return [
    { key: 0, name: '5 %', value: 0.05 },
    { key: 1, name: '10 %', value: 0.1 },
    { key: 2, name: '20 %', value: 0.2 },
    { key: 3, name: '30 %', value: 0.3 },
    { key: 4, name: '50 %', value: 0.5 },
    { key: 5, name: '80 %', value: 0.8 },
  ];
}

export function getPricePenetrationList() {
  return [
    { key: 0, name: '5 %', value: 0.05 },
    { key: 1, name: '10 %', value: 0.1 },
    { key: 2, name: '20 %', value: 0.2 },
    { key: 3, name: '30 %', value: 0.3 },
    { key: 4, name: '50 %', value: 0.5 },
    { key: 5, name: '80 %', value: 0.8 },
  ];
}

export function getMinimumCommitment() {
  return [
    { key: 0, name: '0 %', value: 0.0 },
    { key: 1, name: '5 %', value: 0.05 },
    { key: 2, name: '10 %', value: 0.1 },
    { key: 3, name: '20 %', value: 0.2 },
    { key: 4, name: '30 %', value: 0.3 },
    { key: 5, name: '50 %', value: 0.5 },
    { key: 6, name: '80 %', value: 0.8 },
  ];
}

export function getNoticePeriodList() {
  return [
    { key: 0, name: '1 Month', value: 0.2 },
    { key: 1, name: '3 Month', value: 0.15 },
    { key: 2, name: '1 Year', value: 0.1 },
    { key: 3, name: '3 Years', value: 0 },
    { key: 4, name: '5 Years', value: -0.2 },
  ];
}

export function getExclusivityList() {
  return [
    { key: 0, name: 'None', value: 0 },
    { key: 1, name: 'Us exclusive to you', value: 0.3 },
    { key: 2, name: 'You exclusive to us', value: -0.2 },
    { key: 3, name: 'Mutual exclusivity', value: -0.1 },
  ];
}

export function getPaymentList() {
  return [
    { key: 0, name: 'Upfront', value: -0.2 },
    { key: 1, name: '30 days', value: 0 },
    { key: 2, name: '60 days', value: 0.1 },
    { key: 3, name: '90 days', value: 0.2 },
  ];
}

export function generateVolumeChartSeries(volume, cpp) {
  let discountPerDollarBonus = 0.5;
  let creditPerNewMember = 2;
  let bonusPerPartnerActive = 1;
  // -------
  // let USD = 1
  // let cashbackUserInput = volume?.cashbackPer
  // const decimalPercentage = cashbackUserInput / 100;
  // let commissionInUsd = USD * decimalPercentage;
  // console.log(commissionInUsd,"commissionInUsd")
  // let ffCommission = 0
  // let llCommision = 30

  // let memberProgramCommision =  (commissionInUsd * 0.7).toFixed(3);

  // let cppMemberUSD = cpp?.costPerPoint

  // let proposition = Math.round(memberProgramCommision/cppMemberUSD)
  // console.log(proposition,"cashbackUserInput")
  // ------

  volume.annualLoyaltySales =
    getSalesList()[volume.sales].value * getVolumePenetrationList()[volume.penetration].value;
  volume.annualPointsPotential = Number(
    volume.annualLoyaltySales * getVolumePropositionList()[volume.proposition].value
  );

  volume.series = [];

  volume.series[15] = Math.floor(volume.annualPointsPotential / 4);
  volume.series[3] = Math.floor(volume.series[15] * getGrowthList()[volume.growth].value);
  volume.series[0] = Math.floor(volume.series[3] / 4);
  volume.series[1] = Math.floor(volume.series[0] * 2);
  volume.series[2] = Math.floor(volume.series[0] * 3);
  volume.series[4] = Math.floor(volume.series[3] + (volume.series[15] - volume.series[3]) / 12);
  volume.series[5] = Math.floor(volume.series[4] + (volume.series[15] - volume.series[3]) / 12);
  volume.series[6] = Math.floor(volume.series[5] + (volume.series[15] - volume.series[3]) / 12);
  volume.series[7] = Math.floor(volume.series[6] + (volume.series[15] - volume.series[3]) / 12);
  volume.series[8] = Math.floor(volume.series[7] + (volume.series[15] - volume.series[3]) / 12);
  volume.series[9] = Math.floor(volume.series[8] + (volume.series[15] - volume.series[3]) / 12);
  volume.series[10] = Math.floor(volume.series[9] + (volume.series[15] - volume.series[3]) / 12);
  volume.series[11] = Math.floor(volume.series[10] + (volume.series[15] - volume.series[3]) / 12);
  volume.series[12] = Math.floor(volume.series[11] + (volume.series[15] - volume.series[3]) / 12);
  volume.series[13] = Math.floor(volume.series[12] + (volume.series[15] - volume.series[3]) / 12);
  volume.series[14] = Math.floor(volume.series[13] + (volume.series[15] - volume.series[3]) / 12);

  return volume;
}

export function generatePriceChartSeries(volume, price) {
  // price
  const discountPerPercentBonus = 1;
  const discountPerPercentMinimumComitment = 1;
  const creditPerNewMember = 2;
  const bonusPerActivePartner = 1;

  let contractThreshold = getContractThreshold();

  price.paymentDiscount = getPaymentList()[price.payment].value;

  const minimumCommitmentDiscount =
    discountPerPercentMinimumComitment *
    (0.3 - getMinimumCommitment()[price.minimumCommitment].value);
  price.commitementDiscount =
    getExclusivityList()[price.exclusivity].value +
    getNoticePeriodList()[price.notice].value +
    minimumCommitmentDiscount;
  price.propositionDiscount =
    discountPerPercentBonus * (0.3 - getBonusIssuedPerYear()[price.bonusIssuedPerYear].value);

  price.totalDiscount =
    Number(price.propositionDiscount) +
    Number(price.commitementDiscount) +
    Number(price.paymentDiscount);
  price.basePrice = Number(calculateBasePrice(volume.annualPointsPotential));
  price.targetPrice = Number(price.basePrice - price.basePrice * -price.totalDiscount) || 0;

  price.annualMarketingContribution =
    price.customerPerYear * (price.percentageNewToTheProgram / 100) * creditPerNewMember +
    price.customerPerYear *
      (price.percentageNewToTheProgram / 100) *
      (price.percentageCollectAtOthers / 100) *
      bonusPerActivePartner;

  price.series = [];
  price.series[0] = Math.floor(volume.series[0]);
  price.series[1] = Math.floor(volume.series[1]);
  price.series[2] = Math.floor(volume.series[2]);
  price.series[3] = Math.floor(volume.series[3]);
  price.series[4] = Math.floor(volume.series[4]);
  price.series[5] = Math.floor(volume.series[5]);
  price.series[6] = Math.floor(volume.series[6]);
  price.series[7] = Math.floor(volume.series[7]);
  price.series[8] = Math.floor(volume.series[8]);
  price.series[9] = Math.floor(volume.series[9]);
  price.series[10] = Math.floor(volume.series[10]);
  price.series[11] = Math.floor(volume.series[11]);
  price.series[12] = Math.floor(volume.series[12]);
  price.series[13] = Math.floor(volume.series[13]);
  price.series[14] = Math.floor(volume.series[14]);
  price.series[15] = Math.floor(volume.series[15]);

  price.tierPricing = [
    price.targetPrice + price.targetPrice * (contractThreshold[0].premiumPrice / 100),
    price.targetPrice + price.targetPrice * (contractThreshold[1].premiumPrice / 100),
    price.targetPrice + price.targetPrice * (contractThreshold[2].premiumPrice / 100),
    price.targetPrice + price.targetPrice * (contractThreshold[3].premiumPrice / 100),
    price.targetPrice + price.targetPrice * (contractThreshold[4].premiumPrice / 100),
  ];

  // price.tierVolume = [Math.floor((price.series[15] * (contractThreshold[0].volume / 100))),
  // Math.floor((price.series[15] * (contractThreshold[1].volume / 100))),
  // Math.floor((price.series[15] * (contractThreshold[2].volume / 100))),
  // Math.floor((price.series[15] * (contractThreshold[3].volume / 100))),
  // Math.floor((price.series[15] * (contractThreshold[4].volume / 100))), 0];

  price.tierVolume = [
    Math.floor(price.series[15] * (contractThreshold[0].volume / 100)),
    Math.floor(price.series[15] * (contractThreshold[1].volume / 100)),
    Math.floor(price.series[15] * (contractThreshold[2].volume / 100)),
    Math.floor(price.series[15] * (contractThreshold[3].volume / 100)),
    Math.floor(price.series[15] * (contractThreshold[4].volume / 100)),
    0,
  ];

  price.fourYearCost = fourYearCostCalculation(volume, price);
  console.log(price);
  return price;
}

export function fourYearCostCalculation(volume, price) {
  let fouryearCost = 0;

  const tierVolume = price.tierVolume;

  for (const row of price.series) {
    if (tierVolume[5] >= row && row < tierVolume[4]) {
      fouryearCost += row * price.tierPricing[4];
      continue;
    }

    if (tierVolume[4] >= row && row < tierVolume[3]) {
      fouryearCost += row * price.tierPricing[3];
      continue;
    }

    if (tierVolume[3] >= row && row < tierVolume[2]) {
      fouryearCost += row * price.tierPricing[2];
      continue;
    }

    if (tierVolume[2] >= row && row < tierVolume[1]) {
      fouryearCost += row * price.tierPricing[1];
      continue;
    }

    if (tierVolume[1] >= row && row < tierVolume[0]) {
      fouryearCost += row * price.tierPricing[0];
      continue;
    }
  }

  return fouryearCost;
}

export function calculateBasePrice(projectedVolume) {
  let priceStrategy = JSON.parse(localStorage.getItem('priceStrategy'));
  if (!priceStrategy) {
    priceStrategy = {
      veryLargeSpend: '30000000',
      veryLargeVolume: '40000000',
      veryLargePrice: '0.75',
      largeSpend: '8000000',
      largeVolume: '10000000',
      largePrice: '0.80',
      mediumSpend: '150000',
      mediumVolume: '176000',
      mediumPrice: '0.85',
      smallSpend: '300000',
      smallVolume: '71000',
      smallPrice: '4.20',
      verySmallSpend: '500000',
      verySmallVolume: '333000',
      verySmallPrice: '1.50',
    };
  }
  // localStorage.setItem('contractDispriceStrategycount');

  const priceConf = [
    {
      volume: Number(priceStrategy.verySmallVolume),
      price: Number(priceStrategy.verySmallPrice),
      volumeAbove: Number(priceStrategy.smallVolume),
      priceAbove: Number(priceStrategy.smallPrice),
    },
    {
      volume: Number(priceStrategy.smallVolume),
      price: Number(priceStrategy.smallPrice),
      volumeAbove: Number(priceStrategy.mediumVolume),
      priceAbove: Number(priceStrategy.mediumPrice),
    },
    {
      volume: Number(priceStrategy.mediumVolume),
      price: Number(priceStrategy.mediumPrice),
      volumeAbove: Number(priceStrategy.largeVolume),
      priceAbove: Number(priceStrategy.largePrice),
    },
    {
      volume: Number(priceStrategy.largeVolume),
      price: Number(priceStrategy.largePrice),
      volumeAbove: Number(priceStrategy.largeVolume),
      priceAbove: Number(priceStrategy.veryLargePrice),
    },
    { volume: Number(priceStrategy.largeVolume), price: Number(priceStrategy.veryLargePrice) },
  ];

  // finding the closest
  // const closest = priceConf.reduce((prev, curr) => {
  //     return (Math.abs(curr.volumeAbove - projectedVolume)) < (Math.abs(prev.volumeAbove - projectedVolume)) ? curr : prev;
  // });

  const sortedPricing = priceConf.sort((item1, item2) => {
    return item1.volume < item2.volume;
  });
  let closest = {};
  sortedPricing.forEach((item) => {
    if (item.volume < projectedVolume) {
      closest = item;
    }
  });

  console.log(closest);
  const closestPrice = closest.price;
  const closestPriceAbove = closest.priceAbove;
  const closestVolumeAbove = closest.volumeAbove;
  const closestVolume = closest.volume;

  const toAbove = projectedVolume - closestVolumeAbove;
  const toBelow = projectedVolume - closestVolume;
  const volumeDiffernece = toBelow / (closestVolumeAbove - closestVolume);
  const basePrice =
    (closestPriceAbove + (closestPrice - closestPriceAbove) * volumeDiffernece) / 100;
  console.log(basePrice.toFixed(4));
  return Number(basePrice).toFixed(4);
}

// export function calculateFourYearCost(series) {
//     let totalCost = series.reduce((item, acc) => {
//         return acc + item;
//     }, 0);

//     return totalCost || 0;
// }

export function getContractThreshold() {
  let contractThreshold = JSON.parse(localStorage.getItem('priceThreshold'));
  if (!contractThreshold) {
    contractThreshold = [
      { volume: 120, premiumPrice: -10 },
      { volume: 100, premiumPrice: 0 },
      { volume: 75, premiumPrice: 10 },
      { volume: 50, premiumPrice: 15 },
      { volume: 25, premiumPrice: 20 },
    ];
  }
  return contractThreshold;
}

export function getPriceStrategy() {
  let storedPriceStrategy = JSON.parse(localStorage.getItem('priceStrategy'));
  if (!storedPriceStrategy) {
    storedPriceStrategy = {
      veryLargeSpend: '30000000',
      veryLargeVolume: '40000000',
      veryLargePrice: '0.75',
      largeSpend: '8000000',
      largeVolume: '10000000',
      largePrice: '0.80',
      mediumSpend: '150000',
      mediumVolume: '176000',
      mediumPrice: '0.85',
      smallSpend: '30000',
      smallVolume: '71000',
      smallPrice: '1.10',
      verySmallSpend: '5000',
      verySmallVolume: '333000',
      verySmallPrice: '1.50',
    };
  }
  return storedPriceStrategy;
}
