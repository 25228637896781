import { m } from 'framer-motion';
import {
    Button, Typography, TextField, Stack, Container, Grid,
    Stepper,
    StepLabel,
    Box,
    Step,
    Paper,
    StepContent,
    MenuItem,
    CardHeader,
    CardContent,
    Tooltip,
    Card,
    Checkbox,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getDefaultCompanyConfiguration } from 'src/utils/chart_calculation_ver1';
import { varFade, MotionViewport } from '../../components/animate';
import { ChartColumnSingle1 } from '../_examples/extra/chart';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { TableHeadCustom } from 'src/components/table';
import { init } from 'i18next';

export function OnboardingDetails({ companyDetails, handleChange }) {

    const [marketList, setMarketList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);

    useEffect(() => {
        init();

    }, [])

    const init = (async () => {
        const response = await getDefaultCompanyConfiguration();

        setMarketList(response.body[0].config.market);
        setCategoryList(response.body[0].config.category);
        setSubCategoryList(response.body[0].config.subcategory);
    })

   
    return (
        <>
            <Stack style={{ padding: "40px" }} component={MotionViewport} spacing={3}>
                <Typography variant='h4' style={{ textAlign: 'left' }} >Company Details</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <m.div>
                            <TextField size='small' fullWidth label="Company Name*" value={companyDetails.companyName} id="companyName" onChange={(e) => handleChange(e, "companyDetails", "companyName")} />
                            {!companyDetails.companyName && <Typography variant='caption' sx={{ color: 'red' }}>This is required field</Typography>}
                        </m.div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <m.div>
                            <TextField size='small' fullWidth label="Contact Name*" value={companyDetails.contactName} id="contactName" onChange={(e) => handleChange(e, "companyDetails", "contactName")} />
                            {!companyDetails.contactName && <Typography variant='caption' sx={{ color: 'red' }}>This is required field</Typography>}
                        </m.div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <m.div>
                            <TextField size='small' fullWidth label="Contact Email address*" value={companyDetails.contactEmail} id="contactEmail" onChange={(e) => handleChange(e, "companyDetails", "contactEmail")} />
                            {!companyDetails.contactEmail && <Typography variant='caption' sx={{ color: 'red' }}>This is required field</Typography>}
                        </m.div>
                    </Grid>
                    {/* ---------------------------------------------------- */}
                    <Grid item xs={12} md={1}>
                        <m.div>
                            <TextField size='small' fullWidth label="Country Code*" type="number"  value={companyDetails.countryCode}
                            //  id="contactPhoneNo" 
                            id="countryCode" 
                             onChange={(e) => handleChange(e, "companyDetails", "countryCode")}
                           
                              />
                            {!companyDetails.countryCode && <Typography variant='caption' sx={{ color: 'red' }}>This is required field</Typography>}
                        </m.div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <m.div>
                            <TextField size='small' fullWidth label="Phone No*" type="number" value={companyDetails.contactPhoneNo} id="contactPhoneNo" onChange={(e) => handleChange(e, "companyDetails", "contactPhoneNo")} />
                            {!companyDetails.contactPhoneNo && <Typography variant='caption' sx={{ color: 'red' }}>This is required field</Typography>}
                        </m.div>
                    </Grid>
                    {/* ------------------------------------------ */}
                    <Grid item xs={12} md={4}>
                        <m.div>
                            <TextField size='small' fullWidth label="Company Address" value={companyDetails.companyAddress} id="companyAddress" onChange={(e) => handleChange(e, "companyDetails", "companyAddress")} />
                        </m.div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <m.div>
                            <TextField size='small' fullWidth label="City" 
                            value={companyDetails.city}
                             id="city" 
                            //  onChange={(e) => handleChange(e, "companyDetails", "companyAddress")} 
                            onChange={(e) => handleChange(e, "companyDetails", "city")} 
                             />
                        </m.div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <m.div>
                            <TextField size='small' fullWidth label="Country" 
                            // value={companyDetails.companyAddress}
                            value={companyDetails.country}
                             id="country" 
                             onChange={(e) => handleChange(e, "companyDetails", "country")}
                              />
                        </m.div>
                    </Grid>
                    {/* ----------------------------------------- */}
                    <Grid item xs={12} md={3}>
                        <m.div>
                            <TextField size='small' fullWidth label="Company Website*" value={companyDetails.companyWebsite} id="companyWebsite" onChange={(e) => handleChange(e, "companyDetails", "companyWebsite")} />
                            {!companyDetails.companyWebsite && <Typography variant='caption' sx={{ color: 'red' }}>This is required field</Typography>}
                        </m.div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField size='small' variant="outlined" select fullWidth label="Market" value={companyDetails.market} id="market" onChange={(e) => handleChange(e, "companyDetails", "market")} >
                            {marketList && marketList.map((item) => (
                                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                            ))}

                        </TextField>
                        {/* <m.div style={{ background: '#b5b5b5', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Country or region in which the partnership will be made available to collectors.</Typography>
                    </m.div> */}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField size='small' variant="outlined" select fullWidth label="Category" value={companyDetails.category} id="category" onChange={(e) => handleChange(e, "companyDetails", "category")} >
                            {categoryList && categoryList.map((item) => (
                                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                            ))}
                        </TextField>
                        {/* <m.div style={{ background: '#b5b5b5', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Category which best describes the partner’s main business</Typography>
                    </m.div> */}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <m.div>
                            <TextField size='small' variant="outlined" select fullWidth label="Sub Category" value={companyDetails.category} id="subCategory" onChange={(e) => handleChange(e, "companyDetails", "subCategory")} >
                                {subCategoryList && subCategoryList.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                ))}
                            </TextField>
                        </m.div>
                        {/* <m.div style={{ background: '#b5b5b5', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Sub-category which best describes the partner’s main business</Typography>
                    </m.div> */}
                    </Grid>
                    <Typography variant='subtitle2' style={{ color: '#f44747', marginTop: '20px' }}>* Required Fields</Typography>
                </Grid>
            </Stack>
        </>
    )
}